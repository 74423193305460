<template>
	<div class="back">
		<p class="title">请选择注册账号类型</p>
		<div class="box">
			<div class="thatperson" @click="getregister('/rersonalRegistration')">
				<p class="user">个人用户</p>
				<p class="usertitle">个人账户主要应用于裁判员注册、
					信息浏览等个人业务。</p>
			</div>
			<div class="groupzc" @click="getregister('/groupRegistration')">
				<p class="user">团体用户</p>
				<p class="usertitle">团体账户主要应用于赛事信息登记、
					裁判员管理等团体业务。</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {}
		},
		created() {},
		methods: {
			getregister(path){
				this.$router.push({path: path})
			}
		}
	}
</script>

<style  lang="scss" scoped>
	.back {
		width: 1200PX;
		margin: 0 auto;
		height: 864PX;
		background: #F8FBFF;
		border-radius: 22PX;
		margin-top: 59PX;
		margin-bottom: 30PX;
	}

	.title {
		width: 1200PX;
		text-align: center;
		font-size: 32PX;
		font-weight: 400;
		color: #000000;
		padding-top: 64PX;
	}

	.box {
		display: flex;
		justify-content: center;
		margin-top: 51PX;
	}

	.thatperson {
		width: 461PX;
		height: 598PX;
		background: url('../assets/image/thatperson.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		cursor: pointer;
	}

	.groupzc {
		width: 461PX;
		height: 598PX;
		background: url('../assets/image/groupzc.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-left: 58PX;
		cursor: pointer;
	}
	
	.user{
		font-size: 30PX;
		font-weight: bold;
		color: #2368E1;
		text-align: center;
		margin-top: 354PX;
	}
	.usertitle{
		width: 352PX;
		font-size: 21PX;
		font-weight: 400;
		color: #383838;
		text-align: center;
		margin: 30PX auto 0;
	}
</style>